import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, LinkContact } from "../../components/Core";
import bgFooter from "../../assets/image/png/subscribe-pattern.png";
import spirit from "../../assets/image/png/spirit-logo.png";
import inkubator from "../../assets/image/png/inkubator-logo.png";

const SectionStyled = styled(Section)`
	&.pattern::before {
		position: absolute;
		bottom: -150px;
		content: '';
		width: 120%;
		height: 150%;
		/* background: url(${bgFooter}) bottom center no-repeat; */
		background-size: cover;
		z-index: -1;
	}
`;

const Contact = ({ pattern = true }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled
        className={`position-relative ${pattern ? "pattern" : ""}`}
      >
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="11" xl="10" className="text-center">
              <Box>
                <Title variant="secSm">
                  Kako lahko pomagam pri vašem naslednjem projektu?
                </Title>
                <Box
                  className="text-center d-flex justify-content-center"
                  mt={["3rem", null, "4rem"]}
                >
                  <div className="d-flex flex-column flex-lg-row justify-content-center">
                    <LinkContact
                      href="mailto:sanja.hadjur@typo.si"
                      target="_blank"
                      className="mb-2 mb-lg-0"
                    >
                      sanja.hadjur@typo.si
                    </LinkContact>
                    <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                      |
                    </span>
                    <LinkContact href="">041 201 878</LinkContact>

                    <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                      |
                    </span>
                    <LinkContact
                      href="https://si.linkedin.com/in/sanjahadjur"
                      target="_blank"
                    >
                      linkedin
                    </LinkContact>
                    <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                      |
                    </span>
                    <LinkContact
                      href="https://www.instagram.com/sanjahadjur_design"
                      target="_blank"
                    >
                      Instagram
                    </LinkContact>
                  </div>
                </Box>
              </Box>
              <LogoBox>
                <LogoContainer className="w-20 img-fluid">
                  <a
                    href="https://ric-belakrajina.si/sl/podjetniski-inkubator/"
                    target="_blank"
                  >
                    <img
                      src={inkubator}
                      alt="inkubator-logo"
                      className="img-fluid"
                    />
                  </a>
                </LogoContainer>{" "}
                <LogoContainer className="w-20 img-fluid">
                  <a href="https://www.spiritslovenia.si/" target="_blank">
                    <img src={spirit} alt="spirit-logo" className="img-fluid" />
                  </a>
                </LogoContainer>
              </LogoBox>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Contact;

const LogoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;
const LogoContainer = styled.div`
  padding-left: 30px;
  width: 160px;
  align-items: flex-start;
`;
